import React, { useState, useEffect, useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { UserContext } from '../Provider/UserProvider'

// import BGIMG from '../assets/cart.svg'
import '../style/sidebar.css'

import Homepage from '../screens/Homepage'
import Recommendation from "../screens/Recommendation";
import Dashboard from "../screens/Admin/Dashboard";
import Search from '../screens/Search';
import Suggestion from '../screens/Suggestion';
import EzFooter from '../components/EzFooter';

function Mainlayout() {
    const user = useContext(UserContext);
    return (
        <div className="position-relative">
            {/* <div className="position-absolute" style={{zIndex:-100,top:0,left:50}}>
                <img src={BGIMG} alt="" />
            </div> */}
            <div className="d-md-none">
                <BottomNavigation />
                <Navbar />
            </div>
            <div className="d-flex h-100">
                <div className="sidebar-content d-none d-md-block">
                    <Sidebar />
                </div>
                <div className="col main-content p-0">
                    <div className="">
                        <Switch>
                            <Route path="/suggestion" component={Suggestion} />
                            <Route path="/coupons" component={Recommendation} />
                            <Route path="/search" component={Search} />
                            <Route path="/admin/dashboard" render={(props) => {
                                if (user?.uid === "Nb2uPZ0hYGcRTS7LjtXomNYjUxp2")
                                    return <Dashboard {...props} />
                                else return <Homepage {...props} />
                            }} />
                            <Route path="/" exact component={Homepage} />
                            {/* <Route component={Homepage} /> */}
                        </Switch>
                    </div>
                    <EzFooter />     
                </div>
            </div>
        </div>
    )
}

export default Mainlayout
