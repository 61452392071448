import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../Provider/UserProvider'
import { auth, provider } from '../firebase'

function BottomNavigation() {

    const history = useHistory();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false)
    const user = useContext(UserContext);
    const [currentThemeMode, setcurrentThemeMode] = useState(() => localStorage.getItem('theme') || 'dark')

    useEffect(() => {
        console.log(currentThemeMode)
        localStorage.setItem('theme', currentThemeMode)
    }, [currentThemeMode])

    const signInWithGoogle = () => {
        auth.signInWithPopup(provider);
    }

    const signOut = () => {
        auth.signOut().then(() => {
            console.log('sosf')
            // Sign-out successful.
        }).catch((error) => {
            console.log('sof')
            // An error happened.
        });
    }

    const toggleDarkMode = () => {
        if (document.body.classList.contains("light")) {
            document.body.classList.remove("light");
            document.body.classList.add("dark");
            setcurrentThemeMode('dark')
        } else {
            document.body.classList.remove("dark");
            document.body.classList.add("light");
            setcurrentThemeMode('light')
        }


    }

    return (
        <div className="bn d-md-none d-flex w-100 justify-content-around">
            <div
                className={`text-center bn-link `}
                onClick={() => history.push('/')}>
                <i className={`eva ${location.pathname === "/" ? 'eva-home' : 'eva-home-outline'} bn-link__icon`}></i>
                <div className="bn-link__text">Home</div>
            </div>
            <div
                className={`text-center bn-link`}
                onClick={() => history.push('/coupons')}>
                <i className={`eva ${location.pathname === "/coupons" ? 'eva-gift' : 'eva-gift-outline'} bn-link__icon`}></i>
                <div className="bn-link__text">Coupons</div>
            </div>
            <div
                className={`text-center bn-link `}
                onClick={() => history.push('/search')}>
                <i className={`eva ${location.pathname === "/search" ? 'eva-search' : 'eva-search-outline'} bn-link__icon`}></i>
                <i class="eva  bn-link__icon"></i>
                <div className="bn-link__text">Search</div>
            </div>
            <div
                className={`text-center bn-link`}
                onClick={() => history.push('/suggestion')}>
                <i className={`eva ${location.pathname === "/suggestion" ? 'eva-bulb' : 'eva-bulb-outline'} bn-link__icon`}></i>
                <div className="bn-link__text">Suggestion</div>
            </div>
            <div className={`position-relative text-center bn-link ${showMenu ? 'active' : ''}`}
                onClick={() => setShowMenu(!showMenu)}>
                <i class="eva eva-settings-2-outline bn-link__icon"></i>
                <div className="bn-link__text">More</div>
            </div>
            {showMenu &&
                <CustomPopupMenu
                    height=""
                    width="97vw"
                    positionTop="-150px"
                    positionRight={window.innerWidth < 400 ? "4px" : "7px"}>
                    <div className="row justify-content-evenly my-2 mb-3 ez-popup-menu">
                        {user?.uid === "Nb2uPZ0hYGcRTS7LjtXomNYjUxp2" && <div className="col-3 my-1 mb-2">
                            <NavMenuButton
                                onClick={() => {
                                    history.push('/admin/dashboard')
                                }}
                                icon={<i class="eva eva-settings-outline bn-link__icon"></i>}
                                text="Admin Dashboard"
                            />
                        </div>}
                        <div className="col-3 my-1">
                            <NavMenuButton
                                onClick={toggleDarkMode}
                                // icon={currentThemeMode === "dark" && <i className="fas fa-sun"></i>}
                                icon={currentThemeMode === "light" ? <i className="fas fa-sun"></i> : <i className=" far fa-moon"></i>}
                                text={currentThemeMode === "light" ? "dark" : "light"}
                            />
                        </div>
                        <div className="col-3 my-1  mb-2">
                            <NavMenuButton
                                icon={<i class="eva eva-bulb-outline bn-link__icon"></i>}
                                text="Menu 1"
                            />
                        </div>
                        <div className="col-3 my-1  mb-2">
                            <NavMenuButton
                                icon={<i class="eva eva-bulb-outline bn-link__icon"></i>}
                                text="Menu 1"
                            />
                        </div>
                        <div className="col-3 my-1  mb-2">
                            <NavMenuButton
                                icon={<i class="eva eva-bulb-outline bn-link__icon"></i>}
                                text="Menu 1"
                            />
                        </div>
                        <div className="col-3 my-1 ">
                            <NavMenuButton
                                icon={<i class="eva eva-bulb-outline bn-link__icon"></i>}
                                text="Menu 1"
                            />
                        </div>
                        <div className="col-3 my-1">
                            <NavMenuButton
                                onClick={auth.currentUser ? signOut : signInWithGoogle}
                                icon={user
                                    ? <i class="eva eva-power fs-4 my-auto"></i>
                                    : <i class="eva eva-log-in fs-3 my-auto"></i>}
                                text={user
                                    ? "Sign out"
                                    : "Login"
                                }
                            />

                        </div>
                    </div>
                </CustomPopupMenu>}
        </div>
    )
}

export default BottomNavigation

const NavMenuButton = ({ onClick, text, icon }) => {
    return (<div>
        <div
            className={`text-center  bn-link `}
            onClick={onClick}>
            {icon}
            <div className="bn-link__text">{text}</div>
        </div>
    </div>)
}

const CustomPopupMenu = ({ width, positionTop, positionBottom, positionRight, positionLeft, children }) => {
    return (
        <div
            className="position-absolute bg-primary p-2"
            style={{
                borderRadius: 10,
                // zIndex: 999999,
                boxShadow: `inset 0 0 15px var(--bs-color)`,
                top: positionTop, bottom: positionBottom, left: positionLeft, right: positionRight, width: width
            }}>
            {children}
        </div>
    )
}
