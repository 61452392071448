import React, { useState, useEffect, useContext } from 'react'
import { useHistory, NavLink } from 'react-router-dom';

import { UserContext } from '../Provider/UserProvider'
import '../style/navbar.css';

import { auth, authFn, provider } from '../firebase'

function Navbar() {
    const history = useHistory();
    const user = useContext(UserContext);
    console.log("user---", user)
    const [isAuth, setisAuth] = useState(false);
    const [currentThemeMode, setcurrentThemeMode] = useState(() => localStorage.getItem('theme') || 'dark')

    useEffect(() => {
        console.log(currentThemeMode)
        localStorage.setItem('theme', currentThemeMode)
    }, [currentThemeMode])

    const signInWithGoogle = () => {
        auth.signInWithPopup(provider);
    }
    const signOut = () => {
        auth.signOut().then(() => {
            console.log('sosf')
            // Sign-out successful.
        }).catch((error) => {
            console.log('sof')
            // An error happened.
        });
    }

    return (
        <div className="navbar sticky">
            <div className="container-fluid d-flex justify-content-between">
                <div className="d-md-none brand mx-auto mx-md-1">
                    <div className="brand-logo"></div>
                    <div className="brand-text">
                        Easy Commerce
                    </div>
                </div>
                <div className="d-flex d-md-none">
                    {auth.currentUser
                        ? <button
                            onClick={signOut}
                            className="btn btn-primary d-none d-md-inline-block">Sign out</button>
                        : <button
                            onClick={signInWithGoogle}
                            className="btn btn-primary d-none d-md-inline-block">Login</button>}
                </div>
            </div>
        </div>
    )
}

export default Navbar
