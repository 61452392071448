import React, { useState, useEffect } from 'react'
import ContentLoader from 'react-content-loader';
import ProductCard from '../components/ProductCard';
import { db } from '../firebase'

function Suggestion() {
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const dataGetter = db.ref('Products/');
        dataGetter.on('value', (data) => {
            setLoading(false)
            console.log(data.val());
            setProduct(data.val())
        })
    }, [])

    return (
        <div className="p-2 pb-5">
            {loading
                ? <ContentLoader
                    speed={2}
                    width={1400}
                    height={660}
                    // viewBox="0 0 1000 1060"
                    backgroundColor="var(--bg-color2)"
                    foregroundColor="var(--bg-color)"
                >
                    <rect x="10" y="0" rx="12" ry="12" width="400" height="246" />
                    <rect x="420" y="0" rx="12" ry="12" width="400" height="246" />
                    <rect x="830" y="0" rx="12" ry="12" width="400" height="246" />
                    <rect x="10" y="260" rx="12" ry="12" width="400" height="246" />
                    <rect x="420" y="260" rx="12" ry="12" width="400" height="246" />
                    <rect x="830" y="260" rx="12" ry="12" width="400" height="246" />
                    <rect x="10" y="520" rx="12" ry="12" width="400" height="246" />
                    <rect x="420" y="520" rx="12" ry="12" width="400" height="246" />
                    <rect x="830" y="520" rx="12" ry="12" width="400" height="246" />
                    {/* <rect x="48" y="0" rx="12" ry="12" width="1120" height="246" /> */}
                </ContentLoader>
                : <div className="row g-2 justify-content-center pb-5 pb-md-0">
                        {Object.values(product || {}).map((product, i) => {
                            return <div key={i} className="col-6 col-md-6 col-lg-4" key={i}>
                                <ProductCard
                                    brand={product.brand}
                                    image={product.image}
                                    productName={product.name}
                                    link={product.link}
                                    oldPrice={product.oldPrice || 0}
                                    newPrice={product.newPrice || 0}
                                    ratings={product.rating || 0}
                                />
                            </div>
                        })}
                    </div>}
        </div>
    )
}

export default Suggestion
