import React, { useState } from 'react'
import toast from 'react-hot-toast';
import EzInput from '../../components/EzInput';
import { db } from '../../firebase'
import BannerDataUpload from './BannerDataUpload';

function Dashboard() {
    const [name, setname] = useState("");
    const [brand, setbrand] = useState("")
    const [link, setlink] = useState("")
    const [image, setimage] = useState("")
    const [oldPrice, setoldPrice] = useState(null)
    const [newPrice, setnewPrice] = useState(null)
    const [rating, setrating] = useState(null)

    const createProduct = (e) => {
        e.preventDefault()
        if (formValidated()) {
            const productRef = db.ref(`Products/${name}`)
            productRef.set({ name, brand, oldPrice, newPrice, rating, link, image }).then(res => {
                console.log('data updated')
                toast.success("Product added succesfully")
                setname(""); setbrand(""); setimage(""); setimage(""); setoldPrice(null); setnewPrice(null); setrating(null)
            }).catch(err => {
                toast.error(err)
                console.log('unable to create product', err)
            })
        }
    }
    const resetData = () => {
        setname(""); setbrand(""); setimage(""); setimage(""); setoldPrice(null); setnewPrice(null); setrating(null)
    }
    const formValidated = () => {
        if (name.trim().length === 0) {
            toast.error("Enter valid product name");
            return false
        } else if (brand.trim().length === 0) {
            toast.error("Enter valid brand name");
            return false
        }
        return true
    }
    return (
        <div className="container-xxl">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-6 col-xl-4 text-md-center">
                    <div className="fw-bold fs-3 my-2 text-primary">
                        Add Product
                    </div>
                    <form onSubmit={createProduct} onReset={resetData}>
                        <div className="my-2">
                            <EzInput
                                value={name}
                                type="text" placeholder="Product Name" onInput={(e) => setname(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput
                                value={brand} type="text" placeholder="Brand Name" onInput={(e) => setbrand(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput
                                type="url" value={image} placeholder="Image Link" onInput={(e) => setimage(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput
                                type="url" value={link} placeholder="Affiliate Link" onInput={(e) => setlink(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput
                                type="number" value={oldPrice} placeholder="Old Price" onInput={(e) => setoldPrice(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput
                                type="number" value={newPrice} placeholder="New Price" onInput={(e) => setnewPrice(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <EzInput type="text" value={rating} placeholder="Ratings" onInput={(e) => setrating(e.target.value)} />
                        </div>
                        <div className="my-3 d-flex justify-content-between">
                            <input type="reset" value="Clear" className="btn btn-link" />
                            <input type="submit" value="Create" className="btn btn-primary" />
                        </div>
                    </form>

                </div>
                <div className="col-12 col-md-10 col-lg-6 col-xl-4 text-md-center">
                    <BannerDataUpload />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
