import React, { useState, useEffect, useContext } from 'react'
import { useHistory, NavLink } from 'react-router-dom';

import { UserContext } from '../Provider/UserProvider'
import { auth, provider } from '../firebase'

import EZC from '../assets/logo-primary.png'
import EZC2 from '../assets/secondary.png'

import '../style/sidebar.css'
import '../style/navbar.css';

function Sidebar() {
    const history = useHistory()
    const user = useContext(UserContext);
    const [currentThemeMode, setcurrentThemeMode] = useState(() => localStorage.getItem('theme') || 'dark')

    useEffect(() => {
        localStorage.setItem('theme', currentThemeMode)
    }, [currentThemeMode])

    const signInWithGoogle = () => {
        auth.signInWithPopup(provider);
    }
    const signOut = () => {
        auth.signOut().then(() => {
            console.log('sosf')
            // Sign-out successful.
        }).catch((error) => {
            console.log('sof')
            // An error happened.
        });
    }

    const toggleDarkMode = () => {
        if (document.body.classList.contains("light")) {
            document.body.classList.remove("light");
            document.body.classList.add("dark");
            setcurrentThemeMode('dark')
        } else {
            document.body.classList.remove("dark");
            document.body.classList.add("light");
            setcurrentThemeMode('light')
        }


    }
    return (
        <div className="sidebar h-100">
            <div className="brand mx-auto mx-md-1">
                <div className="brand d-flex cp" onClick={() => history.push('/')}>
                    <div className="brand-logo">
                        {
                            currentThemeMode === "darker"
                                ? <img src={EZC2} style={{ width: 40, height: 40 }} />
                                : <img src={EZC} style={{ width: 40, height: 40 }} />
                        }
                    </div>
                    <div className="sb-brand-text ms-2" >Easy Commerce</div>
                </div>
                <NavLink
                    to="/"
                    exact
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i className="sidebar-link__icon fs-2 eva eva-home-outline"></i>
                    <div className="my-auto mx-1">
                        Home
                    </div>
                </NavLink>
                <NavLink
                    to="/ezcommerce"
                    exact
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i className="sidebar-link__icon fs-2 eva eva-shopping-cart-outline"></i>
                    <div className="my-auto mx-1">
                        ezcommerce
                    </div>
                </NavLink>
                <NavLink
                    to="/eztube"
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i className="sidebar-link__icon fs-2 eva eva-video-outline"></i>
                    <div className="my-auto mx-1">
                        EzTube
                    </div>
                </NavLink>
                <NavLink
                    to="/ezgram"
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i class="eva eva-image-outline sidebar-link__icon fs-2 my-auto"></i>
                    <div className="my-auto mx-1">EzGram</div>
                </NavLink>
                <NavLink
                    to="/suggestion"
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i className="sidebar-link__icon eva eva-bulb-outline fs-2"></i>
                    <div className="my-auto mx-1">
                        Suggestion
                    </div>
                </NavLink>
                <NavLink
                    to="/coupons"
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i class="eva eva-gift-outline sidebar-link__icon fs-2 my-auto"></i>
                    <div className="my-auto mx-1">Coupons</div>
                </NavLink>
                {user?.uid === "Nb2uPZ0hYGcRTS7LjtXomNYjUxp2" && <NavLink
                    to="/admin/dashboard"
                    className="nav-link d-flex"
                    activeClassName="active"
                >
                    <i class="eva eva-settings-outline sidebar-link__icon fs-2 my-auto"></i>
                    <div className="my-auto mx-1">Admin Dashboard</div>
                </NavLink>}
            </div>
            <div className="w-100 p-2 d-flex justify-content-between user-content">
                <div className="text-primary d-flex cp w-100" onClick={auth.currentUser ? signOut : signInWithGoogle}>
                    {user
                        ? <i class="eva eva-power fs-4 my-auto"></i>
                        : <i class="eva eva-log-in fs-3 my-auto"></i>}
                    <div className="my-auto nav-link">
                        {user
                            ? "Sign out"
                            : "Login"
                        }
                    </div>

                </div>
                <div
                    onClick={toggleDarkMode}
                    className="cp my-auto mx-2"
                    style={{ color: "var(--text-color)" }}>
                    {currentThemeMode === "dark" && <i className="fas fa-sun"></i>}
                    {currentThemeMode === "light" && <i className=" far fa-moon"></i>}
                </div>

            </div>
        </div >
    )
}

export default Sidebar
