import React from 'react'

function EzFooter() {
    return (
        <div
            className="text-center py-1 px-2 position-absolut w-100 text-primary mb-5 pb-5 pb-md-0 mb-md-0"
            style={{ borderTop: '1px solid var(--text-color)', bottom: 0 }}>
            <div>
                <div className=" my-2 d-flex justify-md-content-end justify-content-evenly">
                    <span className="my-auto d-md-inline-block d-inline">Follow us on - </span>
                    <i
                        onClick={() => window.open('')}
                        class="cp fab fa-instagram fs-2 mx-1"
                        style={{
                            color: "#833AB4"
                        }}
                    ></i>
                    <i
                        onClick={() => window.open('')}
                        class="cp fab fa-youtube fs-2 mx-1"
                        style={{
                            color: "#FF0000"
                        }}
                    ></i>
                    <i
                        onClick={() => window.open('')}
                        class="cp fab fa-facebook fs-2 mx-1"
                        style={{
                            color: "#4267B2"
                        }}
                    ></i>
                    <i
                        onClick={() => window.open('')}
                        class="cp fab fa-twitter fs-2 mx-1"
                        style={{
                            color: "#1DA1F2"
                        }}
                    ></i>
                </div>
                <div className="my-2">
                    © {new Date().getFullYear()} ezcommify - All rights reserved
                </div>
            </div>
        </div>
    )
}

export default EzFooter
