import React, { useState, useEffect } from 'react'
import EzInput from '../components/EzInput';
import { Helmet } from "react-helmet";

function Search() {
    useEffect(() => {
        // const script = document.createElement("script");
        // script.src = "https://use.typekit.net/foobar.js";
        // script.async = true;
        // document.body.appendChild(script);
    }, [])
    return (
        <div className="p-1">
            <h2 className="text-primary">Search Page</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <EzInput type="search" placeholder="Search Amazon Product" />
                </div>
            </div>
        </div>
    )
}

export default Search
