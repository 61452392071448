import React from 'react'

function EzInput({ prefixIcon, postfixIcon, ...props }) {
    const style = {
        label: {
            border: 0,
            background: 'var(--input-color)',
            // maxWidth: 500,
            width:'100%',
            borderRadius:12,
            padding:5
        },
        input: {
            height:40,
            background: 'transparent',
            width: '100%',
            outline: "none",
            border: 'none'
        },
        icon: {
            width: '10%'
        }
    }
    return (
        <label className="d-flex" htmlFor={props?.name} style={style.label}>
            {/* <div className="" style={style.icon}>C </div> */}
            <input {...props} style={style.input} />
        </label>
    )
}

export default EzInput
