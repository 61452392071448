import React, { useState, useEffect } from "react";
import _ from "lodash";

function EcRatings({ noOfStars = 5, rated, size, style }) {
    const [stars, setStars] = useState(null);
    useEffect(() => {
        let divs = (
            <div className="d-inline-block" style={{ fontSize: size || 14 }}>
                {_.times(rated, () => (
                    <i
                        className="ec-rating__icon fas fa-star"
                        style={{ marginRight: 3, ...style, color: 'var(--text-color)' }}
                    ></i>
                ))}
                {_.times(noOfStars - rated, () => (
                    <i
                        className="ec-rating__icon far fa-star "
                        style={{ marginRight: 3, color: 'var(--text-color)' }}
                    ></i>
                ))}
                <span className="ec-rating__text mx-2 fw-bold" style={{ color: 'var(--text-color)' }}>
                    {rated} of {noOfStars}
                </span>
            </div>
        );
        setStars(divs);
    }, [noOfStars, rated]);

    return stars;
}

export default EcRatings;