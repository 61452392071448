import React, { useState, useEffect } from 'react'
import ContentLoader from "react-content-loader"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { db } from '../../firebase'

// import 'react-alice-carousel/lib/alice-carousel.css';

// import img1 from '../../assets/image.png'
// import img2 from '../../assets/image_1.png'
// import img3 from '../../assets/image_2.png'

function InfoBanner() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);

    useEffect(() => {
        db.ref('banner/').on('value', snapshot => {
            setLoading(false)
            setData(snapshot.val())
        });
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // className:'px-5',
        centerPadding: window.innerWidth > 600 ? '40px' :'10px',
        easing: 'ease-in-out',
        centerMode: true,
        initialSlide: 1,
    };

    return (
        <div className="my-2 mb-3">
            <div className="info-banner">
                {loading
                    ? <ContentLoader
                        speed={2}
                        // width={1400}
                        // height={560}
                        viewBox="0 0 1200 800"
                        backgroundColor="var(--bg-color2)"
                        foregroundColor="var(--bg-color)"
                    >
                        <rect x="0" y="0" rx="12" ry="12" width="1200" height="300" />
                    </ContentLoader>
                    : <Slider {...settings}>
                        {Object.values(data).map(d => {
                            return <div className="info-banner__item px-1" data-value="4" onDoubleClick={() => window.open(d.link)}>
                                <img src={d.img} alt="" className="info-banner__img" />
                            </div>
                        })}
                    </Slider>
                }</div>
        </div>
    )
}

export default InfoBanner
