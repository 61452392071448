import React from 'react'
import EcRatings from './EcRating'

function ProductCard({ brand, link, image, oldPrice, newPrice, productName, productDesc, ratings }) {
    return (
        <div className="pcard" >
            <div className="pcard-img">
                <img src={image} alt={productName} className="h-100 w-100" />
            </div>
            <div className="pcard-body p-2">
                <div className="pcard-brand">
                    {brand}
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="pcard-name">{productName}</div>
                        <div className="d-md-none">
                            <EcRatings noOfStars="5" rated={ratings} />
                        </div>
                    </div>
                    <div className="pcard-price">
                        <div className="pcard-price__new">₹ {newPrice}</div>
                        <div className="pcard-price__old text-end">₹ {oldPrice}</div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <EcRatings noOfStars="5" rated={ratings} />
                </div>
                <div className="text-center my-md-2">
                    <button className="btn btn-primary pcard-btn" onClick={() => window.open(link)}>
                        View in Amazon
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProductCard
