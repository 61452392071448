import React from 'react'
import logo from '../assets/logo.png'

function Recommendation() {
    return (
        <div className="text-center" style={{ color: 'var(--text-color)' }}>
            Coming soon...
            <div className="mt-5">
                <img src={logo} alt="" style={{ height: 200, width: 200 }} />
            </div>
        </div>
    )
}

export default Recommendation
