import React, { useState, useEffect } from 'react'
import InfoBanner from '../components/HPComponent/InfoBanner';
import ProductCard from '../components/ProductCard'
import { db } from '../firebase';
import '../style/homepage.css'

function Homepage() {
    return (
        <div className="container-fluid px-0">
            <div className="container-xl mb-md-2 pb-md-2 mb-5 pb-5 py-2 px-0">
                <InfoBanner />
            </div>
            <div>
                Top Seller
                
            </div>
            <div>
                Cheapest 
            </div>
            <div>Best Under</div>
        </div>
    )
}

export default Homepage
