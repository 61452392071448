import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';

import EzInput from '../../components/EzInput';
import firebase, { storage, db } from '../../firebase'

function BannerDataUpload() {
    const [file, setFile] = useState(null)
    const [name, setName] = useState("")
    const [link, setlink] = useState("");
    const [imageLink, setImageLink] = useState("")

    const onSubmit = (e) => {
        e.preventDefault();
        if (name.trim() && link.trim() && imageLink.trim())
            db.ref(`banner/${name}`).set({
                name, link, img: imageLink
            }).then(res => {
                toast.success("Banner added succesfully")
                setName(null); setlink(null); setFile(null);
            }).catch(err => {
                console.log('unable to create banner', err)
                toast.error(err)
            })
        else   toast.error("All the field are required")

    }

    const onFilePicked = (e) => {
        const pickedFile = e.target.files[0];
        if (pickedFile) {
            const fileRef = storage.ref(`images/banner_img/${pickedFile.name}`).put(pickedFile);
            fileRef.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => { },
                (error) => { },
                () => {
                    fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        toast.success("File uploaded")
                        setImageLink(downloadURL)
                        // console.log('File available at', downloadURL);
                        // setlink(downloadURL)
                    });
                }
            );

        }
    }

    const onReset = () => {
        setFile(null); setName(null); setlink(null)
    }
    return (
        <div className="">
            {/*  <div className="col-12 col-md-10 col-lg-6 col-xl-4 text-md-center"> */}
            <div className="fw-bold fs-3 my-2 text-primary">
                Add Banner
            </div>
            <form onSubmit={onSubmit} onReset={onReset}>
                <div className="my-2">
                    <EzInput
                        type="file" value={file} placeholder="Upload Banner Image" onInput={onFilePicked}
                    />
                </div>
                <div className="my-2">
                    <EzInput
                        type="text" value={name} placeholder="Banner Name" onInput={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="my-2">
                    <EzInput type="url" value={link} placeholder="Enter Redirect Link" onInput={(e) => setlink(e.target.value)} />
                </div>
                <div className="my-3 d-flex justify-content-between">
                    <input type="reset" value="Clear" className="btn btn-link" />
                    <input type="submit" value="Create" className="btn btn-primary" />
                </div>
            </form>

            {/* </div> */}
        </div>

    )
}

export default BannerDataUpload
