import React, { useState, useEffect, createContext } from 'react'
import { useHistory, NavLink } from 'react-router-dom';
import { auth } from '../firebase';

export const UserContext = createContext({ user: null });
function UserProvider({ children }) {
    const history = useHistory();
    const [user, setUser] = useState(null)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user && user?.uid === "Nb2uPZ0hYGcRTS7LjtXomNYjUxp2")
                history.replace('/admin/dashboard')
            setUser(user)
        })
    }, [])
    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
